import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import getShows from '../../../../graphql/queries/getShows.graphql';
import Alert from '@material-ui/lab/Alert';
import ShowCard, { SHOW_CARD_SIZES } from 'components/ShowCard';
import showFiltersStateShape from 'shapes/showFiltersStateShape';
import StructuredData from 'components/StructuredData';
import { makeShowItemList } from 'utils/structuredData';

ShowsGrid.propTypes = {
  showFilters: showFiltersStateShape
};

function ShowsGrid({ showFilters }) {
  const { loading, error, data } = useQuery(getShows, {
    variables: {
      filters: showFilters
    }
  });
  const shows = data?.shows?.items ?? [];

  return (
    <Grid container spacing={2}>
      {error && (
        <Alert severity='error'>
          {`Failed to load shows with that criteria, please reload to try again.`}
        </Alert>
      )}
      {shows && shows.length > 0 && (
        <StructuredData data={makeShowItemList(shows)} />
      )}
      {(loading ? Array.from(new Array(8)) : shows).map((show, index) => (
        <Grid key={index} item xs={12} sm={6} md={3} lg={3}>
          <ShowCard show={show} size={SHOW_CARD_SIZES.sm} loading={loading} shouldLazyLoad={(index > 1)}/>
        </Grid>
      ))}
      {!loading && !error && shows.length === 0 && (
        <Alert severity='info'>
          {`No shows with that criteria available at this time. Check back later!`}
        </Alert>
      )}
    </Grid>
  );
}

export default React.memo(ShowsGrid);
