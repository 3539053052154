import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import FilterMenu from './FilterMenu';
import Popover from 'components/Popover';
import Icon from 'components/Icon';
import showFiltersQueryShape from 'shapes/showFiltersQueryShape';
import showFiltersStateShape from 'shapes/showFiltersStateShape';
import { dataLayerPush } from 'utils/dataLayer';

const FilterContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-end;

  .filter {
    margin-right: 0.5rem;
  }

  button {
    font-size: 0.6875rem;
    padding-top: 0;

    &.active {
      border-bottom: 0.0625rem solid ${theme('colors.ttRed')};

      span,
      svg {
        color: ${theme('colors.ttRed')};
        fill: ${theme('colors.ttRed')};
      }
    }

    &:focus {
      color: ${theme('colors.ttRed')};

      svg {
        fill: ${theme('colors.ttRed')};
      }
    }
  }
`;

ShowFilters.propTypes = {
  filterData: showFiltersQueryShape,
  loading: PropTypes.bool,
  showFilters: showFiltersStateShape,
  setShowFilters: PropTypes.func,
};

function ShowFilters({ filterData, loadFilters, loading, showFilters, setShowFilters }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    dataLayerPush('Opened On Demand page filter menu');
    loadFilters();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <FilterContainer>
      <Popover
        icon={<Icon className='filter' icon='filterSlider' />}
        triggerText='Filter'
        triggerType='link'
        handleClick={handleClick}
        handleClose={handleClose}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        page='on-demand'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <FilterMenu
          filterData={filterData}
          loading={loading}
          handleClose={handleClose}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
        />
      </Popover>
    </FilterContainer>
  );
}

export default React.memo(ShowFilters);
