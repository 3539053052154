/** @module tt/utils/structuredData */

import { getEpisodeThumbnail, getEpisodeVideoMedia, getExpirationDate } from 'utils/episodeHelpers';
import { getShowThumbnailUrl } from 'utils/showHelpers';
import dayjs from 'dayjs';
import config from 'config';
import { getEpisodeUrl } from 'utils/linkUtils';

const logoUrl = 'https://tastytradepublicmedia.s3.amazonaws.com/assets/logos/tt-logo-original-05e5dd25021223dff0203915ecdc9375.png';

export function makeOrganization() {
  return {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    name: 'tastylive',
    url: 'https://www.tastylive.com',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Chicago',
      addressRegion: 'IL',
      postalCode: '60607',
      streetAddress: '19 North Sangamon Street',
    },
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '+1-855-468-2789',
        email: 'support@tastylive.com',
        faxNumber: '+1-773-822-0761',
        contactType: 'customer service',
      },
    ],
    logo: {
      '@type': 'ImageObject',
      '@id': 'https://www.tastylive.com/#logo',
      url: logoUrl,
      caption: 'tastylive',
    },
    image: {
      '@id': 'https://www.tastylive.com/#logo',
    },
  };
}

export function makeVideoObject(episode) {
  if (!episode) {
    return null;
  }

  const {
    description,
    displayTitle,
    publishedAt,
    show,
  } = episode;

  const displayDescription = description.length ? description : show.description;
  const media = getEpisodeVideoMedia(episode);

  return {
    '@context': 'http://schema.org',
    '@type': 'VideoObject',
    name: `${displayTitle} | ${show?.title}`,
    description: displayDescription,
    thumbnailUrl: getEpisodeThumbnail(episode, 'lg'),
    uploadDate: publishedAt && (new Date(publishedAt)).toISOString(),
    duration: media?.sources?.duration ? dayjs.duration(media.sources.duration, 'milliseconds') : null,
    contentUrl: media?.mediaUrl,
    embedUrl: media?.mediaFile && makeEmbedUrl(media.mediaFile),
  };
}

export function makeEpisode(episode, { playlist, dailyReplay }) {
  if (!episode) {
    return null;
  }

  const {
    description,
    show,
    publishedAt,
  } = episode;

  const displayDescription = description.length ? description : show.description;

  const organization = makeOrganization();
  const videoObject = makeVideoObject(episode);

  const data = {
    '@context': 'http://schema.org',
    '@type': 'Episode',
    name: episode.displayTitle,
    url: makeAbsoluteUrl(getEpisodeUrl(episode, { playlist, dailyReplay })),
    partOfSeries: {
      '@context': 'http://schema.org',
      '@type': 'CreativeWorkSeries',
      'name': show?.title,
      'description': show?.description || show?.shortDescription || show?.teaser,
      'thumbnailUrl': getShowThumbnailUrl(show, false, 'lg'),
      'inLanguage': 'English', // TODO: language for chinese show
      'author': organization,
    },
    producer: organization,
    productionCompany: organization,
    description: displayDescription,
    thumbnailUrl: getEpisodeThumbnail(episode, 'lg'),
    inLanguage: 'English', // TODO: language for chinese show
    video: videoObject,
    datePublished: publishedAt && (new Date(publishedAt)).toISOString(),
    duration: videoObject?.duration,
  };

  // Add expiration date if video expires.
  const expirationDate = getExpirationDate(episode);
  if (expirationDate) {
    data.expires = (new Date(expirationDate)).toISOString();
  }

  return data;
}

export function makeTVSeries(show) {
  return {
    '@context': 'http://schema.org',
    '@type': 'TVSeries',
    url: makeAbsoluteUrl(show.url),
    name: show?.title,
    description: show?.description || show?.shortDescription || show?.teaser,
    thumbnailUrl: getShowThumbnailUrl(show, false, 'lg'),
    inLanguage: 'English', // TODO: language for chinese show
    productionCompany: makeOrganization(),
  };
}

export function makeEpisodeItemList(episodes = [], { playlist, dailyReplay } = {}) {
  return {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement: episodes.map((episode, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      url: makeAbsoluteUrl(getEpisodeUrl(episode, { playlist, dailyReplay })),
    })),
  };
}

export function makeShowItemList(shows = []) {
  return {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement: shows.map((show, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      url: makeAbsoluteUrl(show.url),
    })),
  };
}

function makeEmbedUrl(videoId) {
  return `${config.mediaConfig.embedBaseUrl}?videoId=${videoId}`;
}

function makeAbsoluteUrl(path) {
  return `${config.prodSiteBaseUrl}${path}`;
}
