import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from 'components/Button';
import Typography from '@material-ui/core/Typography';
import FormatsList from './FormatsList';
import ProductsList from './ProductsList';
import TopicsList from './TopicsList';
import showFiltersQueryShape from 'shapes/showFiltersQueryShape';
import showFiltersStateShape from 'shapes/showFiltersStateShape';
import { trackFilters } from 'utils/showHelpers';

const EMPTY_FILTERS = {
  topicFilters: [],
  formatFilters: [],
  quickFilters: [],
};

const MenuContainer = styled.div`
  padding: 0.625rem 2.5rem 1.875rem;

  ${props => props.theme.breakpoints.up('sm')} {
    min-width: 36.25rem;
  }
`;

const Header = styled(Typography)`
  border-bottom: 0.0625rem solid ${theme('colors.borderGray')};
  color: ${theme('colors.black')};
  font-family: ${theme('typography.fontFamilySecondary')};
  font-size: 0.6875rem;
  font-weight: 700;
  margin: 1.25rem 0 0.625rem;
  padding-bottom: 0.3125rem;
  text-transform: uppercase;
`;

const ListContainer = styled.div`
  .MuiFormControlLabel-label {
    color: ${theme('colors.black')};
    font-size: 0.8125rem;
    line-height: 1rem;
    padding-top: 0.125rem;
  }

  .MuiFormControlLabel-root {
    margin-right: 0;
  }

  .MuiSvgIcon-root {
    color: ${theme('colors.black')};
    font-size: 1.125rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ClearButton = styled(Button)`
  font-size: 0.75rem;
  padding: 0;

  &:hover {
    color: ${theme('colors.ttRed')};
    background-color: transparent;
  }
`;

const DoneButton = styled(Button)`
  border: 0.0625rem solid ${theme('colors.black')};
  width: 100%;
`;

FilterMenu.propTypes = {
  filterData: showFiltersQueryShape,
  loading: PropTypes.bool,
  showFilters: showFiltersStateShape,
  setShowFilters: PropTypes.func,
};

function FilterMenu({ filterData, handleClose, loading, showFilters, setShowFilters }) {
  const [tempShowFilters, setTempShowFilters] = useState(showFilters);
  const showClearFilters = useMemo(() => {
    let showClear = false;
    Object.keys(tempShowFilters).forEach(i => {
      if (tempShowFilters[i].length > 0) {
        showClear = true;
      }
    });
    return showClear;
  }, [tempShowFilters]);

  if (!filterData) {
    return null;
  }

  const { formats, productFilters, topics } = filterData;

  const handleChange = (filterValues = []) => {
    const filterKey = Object.keys(filterValues);
    const filterValue = Object.values(filterValues);

    setTempShowFilters({
      ...tempShowFilters,
      [filterKey]: filterValue[0]
    });
  };

  const filterAndClose = () => {
    setShowFilters(tempShowFilters);
    trackFilters(tempShowFilters);
    handleClose();
  }

  const clearFilters = () => {
    setTempShowFilters(EMPTY_FILTERS);
    setShowFilters(EMPTY_FILTERS);
  }

  return (
    <MenuContainer>
      <ButtonContainer>
        <ClearButton
          disableRipple={true}
          onClick={() => clearFilters()}
          variant='link'
          style={{ visibility: showClearFilters ? 'visible' : 'hidden' }}
        >
          Clear Filters
        </ClearButton>
      </ButtonContainer>
      <Box mb={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Header>Product</Header>
            <ListContainer>
              <ProductsList
                productFiltersData={productFilters}
                showFilters={tempShowFilters}
                setShowFilters={setShowFilters}
                handleChange={handleChange}
              />
            </ListContainer>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Header>Topic</Header>
            <ListContainer>
              <TopicsList
                topicsData={topics}
                showFilters={tempShowFilters}
                setShowFilters={setShowFilters}
                handleChange={handleChange}
              />
            </ListContainer>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Header>Format</Header>
            <ListContainer>
              <FormatsList
                formatsData={formats}
                showFilters={tempShowFilters}
                setShowFilters={setShowFilters}
                handleChange={handleChange}
              />
            </ListContainer>
          </Grid>
        </Grid>
      </Box>
      <DoneButton
        onClick={() => filterAndClose()}
        variant='outlined'
      >
        Apply Filters
      </DoneButton>
    </MenuContainer>
  );
}

export default React.memo(FilterMenu);
