import React, { useState, useMemo } from 'react';
import get from 'lodash.get';
import styled from 'styled-components';
import { useLazyQuery, useQuery } from '@apollo/client';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TabPanel from 'components/Tabs/TabPanel';
import ShowFilters from './components/ShowFilters';
import ControlBar from 'components/ControlBar';
import ModularBlocks from 'components/ModularBlocks';
import PageWrapper from 'components/PageWrapper';
import Tabs from 'components/Tabs';
import SEO from 'components/SEO';
import ShowsGrid from './ShowsGrid';
import pageShape from 'shapes/pageShape';
import getShowFilters from './getShowFilters.graphql';
import useServerCacheHeaders from 'hooks/useServerCacheHeaders';
import httpCacheConfig, { addCacheTags } from 'config/httpCache';

const tabs = [
  { label: 'All Shows' },
  { label: 'Options' },
  { label: 'Futures' },
];

const FILTERS = ['topicFilters', 'formatFilters', 'quickFilters'];

const PageTitle = styled(Typography)`
  font-size: 1.5rem;
  margin: 0;
  padding-top: 2.375rem;
  text-transform: uppercase;
`;

const Divider = styled(ControlBar)`
  margin-bottom: 3rem;
`;

const propTypes = {
  page: pageShape
};

function OnDemandPage({ page }) {
  useServerCacheHeaders(addCacheTags(httpCacheConfig.onDemandPage, [
    page.uid,
    'header',
    'footer'
  ]));
  const [loadFilters, { loading, error, data }] = useLazyQuery(getShowFilters, {
    variables: {
      filters: {
        sortBy: 'title',
        sortDirection: 'descending'
      }
    }
  });

  const [activeTab, setActiveTab] = useState(0);
  const [showFilters, setShowFilters] = useState({
    topicFilters: [],
    formatFilters: [],
    quickFilters: [],
  });

  const { pageBody, seo, title, displayPageTitle = true } = page;
  const tabsId = 'on-demand-tabs';

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const hasFilters = useMemo(() => {
    return FILTERS.find(fieldName => get(showFilters, fieldName, []).length > 0) || false;
  }, [showFilters])

  const newPageBody = (pageBody) => {
    return pageBody.map(function(item, index) {
      if (item.showOneColumn) {
        return {
          ...item,
          showOneColumn: {
            ...item.showOneColumn,
            shouldLazyLoad: index > 0
          }
        }
      }
      else if (item.showTwoColumn) {
        return {
          ...item,
          showTwoColumn: {
            ...item.showTwoColumn,
            shouldLazyLoad: index > 0
          }
        }
      }
      else if (item.showThreeColumn) {
        return {
          ...item,
          showThreeColumn: {
            ...item.showThreeColumn,
            shouldLazyLoad: index > 0
          }
        }
      }
      else {
        return { ...item }
      }
    })
  }

  return (
    <>
      <SEO title={title} seo={seo} />
      <PageWrapper>
        <Container maxWidth='lg'>
          <Divider color='medium'>
            <Grid item xs={12}>
              {displayPageTitle && title && (
                <PageTitle variant='h1'>{title}</PageTitle>
              )}
              <ShowFilters
                filterData={data}
                loading={loading}
                loadFilters={loadFilters}
                showFilters={showFilters}
                setShowFilters={setShowFilters}
              />
            </Grid>
            {/*<Grid item>
              <Tabs
                tabs={tabs}
                id={tabsId}
                height='38px'
                value={activeTab}
                onChange={handleTabChange}
                controlBar
              />
            </Grid>*/}
          </Divider>
          {pageBody && !hasFilters && (
            <Box mt={2.5}>
              <ModularBlocks modularBlocks={newPageBody(pageBody)} />
            </Box>
          )}
          {hasFilters && (
            <Box mt={2.5}>
              <ShowsGrid showFilters={showFilters} />
            </Box>
          )}
          {/*<TabPanel id={tabsId} value={activeTab} index={0}>
            {pageBody && (
              <Box mt={2.5}>
                <ModularBlocks modularBlocks={pageBody} />
              </Box>
            )}
          </TabPanel>
          <TabPanel id={tabsId} value={activeTab} index={1}>
            <Box mt={2.5}>
              <ShowsGrid topic='Options' />
            </Box>
          </TabPanel>
          <TabPanel id={tabsId} value={activeTab} index={2}>
            <Box mt={2.5}>
              <ShowsGrid topic='Futures' />
            </Box>
          </TabPanel>*/}
        </Container>
      </PageWrapper>
    </>
  );
}

OnDemandPage.propTypes = propTypes;

export default React.memo(OnDemandPage);
