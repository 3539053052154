import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import CheckboxGroup from 'components/CheckboxGroup';
import singleFilterShape from 'shapes/singleFilterShape';
import showFiltersStateShape from 'shapes/showFiltersStateShape';

ProductsList.propTypes = {
  productFiltersData: singleFilterShape,
  showFilters: showFiltersStateShape,
  setShowFilters: PropTypes.func,
};

function ProductsList({ handleChange, productFiltersData, showFilters, setShowFilters }) {
  const products = get(productFiltersData, 'items', []).map(product => ({
    name: product.title,
    label: product.title,
    checked: get(showFilters, 'quickFilters', []).includes(product.title)
  }));

  return (
    <CheckboxGroup
      values={products}
      onChange={(quickFilters) => handleChange({ quickFilters })}
    />
  );
}

export default React.memo(ProductsList);
