import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import CheckboxGroup from 'components/CheckboxGroup';
import singleFilterShape from 'shapes/singleFilterShape';
import showFiltersStateShape from 'shapes/showFiltersStateShape';

TopicsList.propTypes = {
  showFilters: showFiltersStateShape,
  setShowFilters: PropTypes.func,
  topics: singleFilterShape
};

function TopicsList({ handleChange, topicsData, showFilters, setShowFilters }) {
  const topics = get(topicsData, 'items', []).map(topic => ({
    name: topic.uid,
    label: topic.title,
    checked: get(showFilters, 'topicFilters', []).includes(topic.uid)
  }));

  return (
    <CheckboxGroup
      values={topics}
      onChange={(topicFilters) => handleChange({ topicFilters })}
    />
  );
}

export default React.memo(TopicsList);
