import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import CheckboxGroup from 'components/CheckboxGroup';
import singleFilterShape from 'shapes/singleFilterShape';
import showFiltersStateShape from 'shapes/showFiltersStateShape';

FormatsList.propTypes = {
  formatsData: singleFilterShape,
  showFilters: showFiltersStateShape,
  setShowFilters: PropTypes.func,
};

function FormatsList({ formatsData, handleChange, showFilters, setShowFilters }) {
  const formats = get(formatsData, 'items', []).map(format => ({
    name: format.uid,
    label: format.title,
    checked: get(showFilters, 'formatFilters', []).includes(format.uid)
  }));

  return (
    <CheckboxGroup
      values={formats}
      onChange={(formatFilters) => handleChange({ formatFilters })}
    />
  );
}

export default React.memo(FormatsList);
